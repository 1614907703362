import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { LanguageService } from './shared/services/language.service';
import { AuthService } from './auth/auth.service';
import { HelpersService } from './shared/services/helpers.service';
import { ACCESS_TOKEN } from './shared/constants/general.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from './shared/services/toast-service';
import { DataService } from './shared/services/data.service';
import { filter, firstValueFrom, Observable, switchMap, tap } from 'rxjs';
import { IUser } from './shared/models/IUser.model';
import { Toast } from './shared/enums/toast';
import { Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { NavigationEnd } from '@angular/router';
import { ApplicationTypeEnum } from './shared/enums/applicationType.enum';
import { timeApi, verifyEmailApi } from './shared/constants/api.constants';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Evento';
  user$: Observable<IUser>;

  constructor(
    private languageService: LanguageService,
    private authService: AuthService,
    private helpers: HelpersService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dataService: DataService,
    @Inject(Location) private readonly location: Location,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.helpers.checkItemFromLocalStorage(ACCESS_TOKEN)) {
      this.authService.fetchUser();
    }

    // this.languageService.loadDefaultLangsAndStyles();
  }

  ngOnInit(): void {
    this.getServerTime();
    this.languageService.loadDefaultLangsAndStyles();
    this.user$ = this.authService.user$;
    this.loadGTAG('G-QZKM0VMECK');
  }

  public loadGTAG(gtagCode: string): void {
    if (gtagCode) {
      const file = this.renderer2.createElement('script');
      file.type = `text/javascript`;
      file.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtagCode;

      this.renderer2.appendChild(this.document.head, file);

      const script = this.renderer2.createElement('script');
      script.type = `text/javascript`;
      script.rel = 'preconnect';
      script.text = `
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', '${gtagCode}');
      `;
      this.renderer2.appendChild(this.document.head, script);

      const navEndEvent$ = this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd)
      );

      navEndEvent$.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', gtagCode, { page_path: event.urlAfterRedirects });
        }
      });
    }
  }

  resendActivationLink(): void {
    this.spinner.show();
    const user = this.authService.user.getValue();

    this.recaptchaV3Service
      .execute('otpResendApi')
      .pipe(
        switchMap((token) => {
          return this.dataService.post(`${verifyEmailApi}/retry`, {
            email: user.email,
            recaptcha: token,
          });
        }),
        tap((_) => {
          this.spinner.hide();
          this.toast.show('Success', 'Activation link has been sent', {
            classname: Toast.success,
          });
        })
      )
      .subscribe();
  }

  getServerTime() {
    this.dataService.get(timeApi).subscribe((res) => {
      console.log(res);
      const serverTime = Math.floor(res.currentTime);
      const now = Math.floor(Date.now() / 1000) - 5;
      localStorage.setItem('serverDiff', JSON.stringify(serverTime - now));
    });
  }
}
